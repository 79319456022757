export default {
  headers: [
    {
      id: 'miniHeader',
      logo: {
        alt: 'logo',
        src: '',
      },
      breakpoint: 1267,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: false,
        logo: {
          show: false,
          reverseOrder: false,
          containerClassName: '',
          imageClassName: '',
        },
        headerClassName: 'on-hvar-miniHeader',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-hvar-miniHeader-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'on-hvar-header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '408-596-4490',
            url: 'tel:408-596-4490',
          },
          {
            id: 'on-hvar-header-mail-item',
            iconSet: 'fa',
            className: 'envelope',
            content: 'contact@therealexperts.com',
            url: 'mailto:contact@therealexperts.com',
          },
          {
            id: 'on-hvar-header-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/RealEstateExpertsSV/',
          },
          {
            id: 'on-hvar-header-youtube-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCfit8F7CUtB--2M3KfDCt8w',
          },
          {
            id: 'on-hvar-header-linkedin-item',
            iconSet: 'fab',
            className: 'linkedin-in',
            url: 'https://www.linkedin.com/company/real-estate-experts/',
          },
          {
            id: 'on-hvar-header-zillow-item',
            image: {
              src: 'https://si-homelight.s3.amazonaws.com/sites/hvar/zillow.svg',
              alt: 'zillow logo',
            },
            className: 'on-header-zillow',
            url: 'https://www.zillow.com/profile/BrettJenningsTeam/',
          },
          {
            id: 'on-hvar-header-yelp-item',
            iconSet: 'fab',
            className: 'yelp',
            url: 'https://www.yelp.com/biz/brett-jennings-real-estate-experts-campbell-4',
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: false,
          containerClassName: '',
          imageClassName: 'on-hvar-header-logo',
        },
        type: 'mobile-content', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          className: '',
          content: 'Open',
          hide: true,
        },
        headerClassName: 'on-hvar-miniHeader-mobile',
        headerClassNameOverride: '',
        mobileMenuClassName: '',
        itemClassName: 'on-hvar-miniHeader-mobile-item',
        menuClassName: '',
        menuLabelActiveClassName: '',
        menuItemClassName: '',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [
          {
            id: 'on-hvar-header-phone-item',
            iconSet: 'fa',
            className: 'phone',
            content: '408-596-4490',
            url: 'tel:408-596-4490',
          },
          {
            id: 'on-hvar-header-mail-item',
            iconSet: 'fa',
            className: 'envelope',
            content: 'contact@therealexperts.com',
            url: 'mailto:contact@therealexperts.com',
          },
          {
            id: 'on-hvar-header-fb-item',
            iconSet: 'fab',
            className: 'facebook-f',
            url: 'https://www.facebook.com/RealEstateExpertsSV/',
          },
          {
            id: 'on-hvar-header-youtube-item',
            iconSet: 'fab',
            className: 'youtube',
            url: 'https://www.youtube.com/channel/UCfit8F7CUtB--2M3KfDCt8w',
          },
          {
            id: 'on-hvar-header-linkedin-item',
            iconSet: 'fab',
            className: 'linkedin-in',
            url: 'https://www.linkedin.com/company/real-estate-experts/',
          },
          {
            id: 'on-hvar-header-zillow-item',
            image: {
              src: 'https://si-homelight.s3.amazonaws.com/sites/hvar/zillow.svg',
              alt: 'zillow logo',
            },
            className: 'on-header-zillow',
            url: 'https://www.zillow.com/profile/BrettJenningsTeam/',
          },
          {
            id: 'on-hvar-header-yelp-item',
            iconSet: 'fab',
            className: 'yelp',
            url: 'https://www.yelp.com/biz/brett-jennings-real-estate-experts-campbell-4',
          },
        ],
        items: [],
      },
    },
    {
      id: 'mainHeader',
      logo: {
        alt: 'logo',
        src: 'https://si-homelight.s3.amazonaws.com/sites/hvar/logo.png',
        url: 'https://www.therealexperts.com/',
      },
      breakpoint: 1267,
      desktopMenu: {
        type: 'default', // default, split, center, single, one-offs
        disableOnHoverMenuExpand: true,
        logo: {
          show: true,
          reverseOrder: false,
          containerClassName: 'on-hvar-header-logo-container',
          imageClassName: 'on-hvar-header-logo',
        },
        headerClassName: 'on-hvar-header',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        itemClassName: 'on-hvar-header-item',
        menuClassName: 'on-hvar-header-menu',
        menuLabelActiveClassName: 'on-hvar-header-folder-item-open',
        menuItemClassName: 'on-hvar-header-folder-item',
        subMenuClassName: '',
        subMenuLabelActiveClassName: '',
        subMenuItemClassName: '',
        items: [
          {
            id: 'menu-item-properties',
            content: 'PROPERTIES',
            url: 'https://www.therealexperts.com/properties/',
          },
          {
            id: 'menu-item-communities',
            content: 'COMMUNITIES',
            url: 'https://www.therealexperts.com/communities/',
          },

          {
            id: 'menu-item-buying',
            content: 'BUYING',
            menuItems: [
              {
                id: 'submenu-item-buying',
                content: 'BUYING',
                url: 'https://www.therealexperts.com/buying/',
              },
            ],
          },
          {
            id: 'menu-item-selling',
            content: 'SELLING',
            menuItems: [
              {
                id: 'submenu-item-selling',
                content: 'SELLING',
                url: 'https://www.therealexperts.com/selling/',
              },
              {
                id: 'submenu-item-trade-in',
                content: 'TRADE IN',
                url: 'https://trade-in.makingmovingsimple.com',
              },
            ],
          },
          {
            id: 'menu-item-search-homes',
            content: 'SEARCH HOMES',
            url: 'https://www.therealexperts.com/search/#/search?listingType=%5B%22Residential%22%5D&pageSize=250&sortField=daysOnHJI&sortOrder=asc&zoom=10&market=calrets&center=%7B%22lat%22%3A37.43511992383437,%22lng%22%3A-121.99681772656248%7D&propertyType=%5B%5D&keyword=&box=37.88845472256615,-121.36235727734373,36.9790225530353,-122.63127817578123',
          },
          {
            id: 'menu-item-about-us',
            content: 'ABOUT US',
            menuItems: [
              {
                id: 'submenu-item-meet-team',
                content: 'MEET THE TEAM',
                url: 'https://www.therealexperts.com/about-us/',
              },
              {
                id: 'submenu-item-who-we-are',
                content: 'WHO WE ARE',
                url: 'https://www.therealexperts.com/who-we-are/',
              },
              {
                id: 'submenu-item-blog',
                content: 'BLOG',
                url: 'https://bayarearealestatejournal.com/',
              },
              {
                id: 'submenu-item-past-sales',
                content: 'PAST SALES',
                url: 'https://www.therealexperts.com/past-sales/',
              },
              {
                id: 'submenu-item-testimonials',
                content: 'TESTIMONIALS',
                url: 'https://www.therealexperts.com/testimonials/',
              },
            ],
          },
          {
            id: 'menu-item-careers',
            content: 'CAREERS',
            menuItems: [
              {
                id: 'submenu-item-join-team',
                content: 'JOIN OUR TEAM',
                url: 'https://www.therealexperts.com/careers/',
              },
              {
                id: 'submenu-item-training-blog',
                content: 'TRAINING BLOG',
                url: 'https://bearealexpert.com/',
              },
            ],
          },
        ],
      },
      mobileMenu: {
        logo: {
          show: true,
          containerClassName: '',
          imageClassName: 'on-hvar-header-logo',
        },
        type: 'mobile-default', // mobile-default, mobile-reversed, mobile-center
        menuOpener: {
          type: 'hamburger',
          className: '',
          hide: false,
          reverse: false,
        },
        headerClassName: 'on-hvar-header-mobile',
        headerSectionsClassName: '',
        headerClassNameOverride: '',
        headerSectionsClassNameOverride: '',
        mobileMenuClassName: 'on-hvar-header-mobile-menu',
        itemClassName: 'on-hvar-header-mobile-item',
        menuClassName: 'on-hvar-header-mobile-folder',
        menuLabelActiveClassName: '',
        menuItemClassName: 'on-hvar-header-mobile-folder-item',
        subMenuClassName: '',
        subMenuItemClassName: '',
        headerItems: [],
        items: [
          {
            id: 'menu-item-properties',
            content: 'PROPERTIES',
            url: 'https://www.therealexperts.com/properties/',
          },
          {
            id: 'menu-item-communities',
            content: 'COMMUNITIES',
            url: 'https://www.therealexperts.com/communities/',
          },

          {
            id: 'menu-item-buying',
            content: 'BUYING',
            menuItems: [
              {
                id: 'submenu-item-buying',
                content: 'BUYING',
                url: 'https://www.therealexperts.com/buying/',
              },
            ],
          },
          {
            id: 'menu-item-selling',
            content: 'SELLING',
            menuItems: [
              {
                id: 'submenu-item-selling',
                content: 'SELLING',
                url: 'https://www.therealexperts.com/selling/',
              },
              {
                id: 'submenu-item-trade-in',
                content: 'TRADE IN',
                url: 'https://trade-in.makingmovingsimple.com',
              },
            ],
          },
          {
            id: 'menu-item-search-homes',
            content: 'SEARCH HOMES',
            url: 'https://www.therealexperts.com/search/#/search?listingType=%5B%22Residential%22%5D&pageSize=250&sortField=daysOnHJI&sortOrder=asc&zoom=10&market=calrets&center=%7B%22lat%22%3A37.43511992383437,%22lng%22%3A-121.99681772656248%7D&propertyType=%5B%5D&keyword=&box=37.88845472256615,-121.36235727734373,36.9790225530353,-122.63127817578123',
          },
          {
            id: 'menu-item-about-us',
            content: 'ABOUT US',
            menuItems: [
              {
                id: 'submenu-item-meet-team',
                content: 'MEET THE TEAM',
                url: 'https://www.therealexperts.com/about-us/',
              },
              {
                id: 'submenu-item-who-we-are',
                content: 'WHO WE ARE',
                url: 'https://www.therealexperts.com/who-we-are/',
              },
              {
                id: 'submenu-item-blog',
                content: 'BLOG',
                url: 'https://bayarearealestatejournal.com/',
              },
              {
                id: 'submenu-item-past-sales',
                content: 'PAST SALES',
                url: 'https://www.therealexperts.com/past-sales/',
              },
              {
                id: 'submenu-item-testimonials',
                content: 'TESTIMONIALS',
                url: 'https://www.therealexperts.com/testimonials/',
              },
            ],
          },
          {
            id: 'menu-item-careers',
            content: 'CAREERS',
            menuItems: [
              {
                id: 'submenu-item-join-team',
                content: 'JOIN OUR TEAM',
                url: 'https://www.therealexperts.com/careers/',
              },
              {
                id: 'submenu-item-training-blog',
                content: 'TRAINING BLOG',
                url: 'https://bearealexpert.com/',
              },
            ],
          },
        ],
      },
    },
  ],
  footers: [
    {
      id: 'on-hvar-footer-first',
      display: true,
      className: 'on-hvar-footer-section',
      sectionClassName: 'on-hvar-footer-section-container',
      columns: [
        {
          id: 'row-logo',
          className: 'on-hvar-footer-row-logo align-center',
          style: {},
          items: [
            {
              id: 'logo-one',
              type: 'image',
              className: 'on-hvar-footer-logo',
              alt: 'logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/hvar/logo-footer.png',
              linkUrl: 'https://www.therealexperts.com/',
              style: { width: '118px' },
            },
            {
              id: 'logo-two',
              type: 'image',
              className: 'on-hvar-footer-logo-second ms-3',
              alt: 'logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/hvar/logo-equal-white.png',
              style: { width: '50px', verticalAlign: 'bottom' },
            },
          ],
        },
        {
          id: 'row-privacy',
          className: 'on-hvar-footer-row-privacy align-center',
          style: {},
          items: [
            {
              id: 'footer-copyright-text',
              type: 'heading',
              className: 'on-hvar-footer-item',
              style: { justifyContent: 'space-between' },
              data: '© 2022 REAL ESTATE EXPERTS, DRE# 02014153',
            },
            {
              id: 'footer-contact',
              type: 'heading',
              className: 'on-hvar-footer-item',
              style: {},
              data: 'CONTACT',
              url: 'https://www.therealexperts.com/contact/',
            },
            {
              id: 'footer-privacy',
              type: 'heading',
              className: 'on-hvar-footer-item',
              style: {},
              data: 'PRIVACY POLICY',
              url: 'https://www.therealexperts.com/privacy-policy/',
            },
            {
              id: 'footer-socials',
              type: 'socialIcons',
              className: 'on-hvar-footer-socials',
              itemClassName: 'on-hvar-footer-socials-item',
              style: {},
              items: [
                {
                  id: 'footer-fcb',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'facebook-f',
                  url: 'https://www.facebook.com/RealEstateExpertsSV/',
                },
                {
                  id: 'footer-yt',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'youtube',
                  url: 'https://www.youtube.com/channel/UCfit8F7CUtB--2M3KfDCt8w',
                },
                {
                  id: 'footer-li',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'linkedin-in',
                  url: 'https://www.linkedin.com/company/real-estate-experts/',
                },
                {
                  id: 'footer-zillow',
                  className: 'on-hvar-footer-zillow',
                  iconUrl: 'https://si-homelight.s3.amazonaws.com/sites/hvar/zillow.svg',
                  url: 'https://www.zillow.com/profile/BrettJenningsTeam/',
                },
                {
                  id: 'footer-yelp',
                  className: '',
                  iconTypeClassName: 'fab',
                  iconClassName: 'yelp',
                  url: 'https://www.yelp.com/biz/brett-jennings-real-estate-experts-campbell-4',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 'on-hvar-footer-scnd',
      display: true,
      className: 'on-hvar-footer-section',
      sectionClassName: 'on-hvar-footer-section-container',
      columns: [
        {
          id: 'logo-three',
          className: 'on-hvar-footer-row-disclaimer align-center',
          style: {},
          items: [
            {
              id: 'disclaimer-logo',
              type: 'image',
              className: 'on-hvar-footer-logo',
              alt: 'logo',
              url: 'https://si-homelight.s3.amazonaws.com/sites/hvar/logo-mls-listings.png',
              style: { width: '118px' },
            },
            {
              id: 'disclaimer-data',
              type: 'heading',
              className: '',
              style: {},
              data: 'The data relating to real estate for sale on this website comes in part from the Internet Data Exchange program of the MLSListingsTM MLS system. Real estate listings held by brokerage firms other than the office represented by this website are marked with the Internet Data Exchange icon (a stylized house inside a circle) and detailed information about them includes the names of the listing brokers and listing agents. The broker providing this data believes the data to be correct, but advises interested parties to confirm all information before relying on it for a purchase decision. The information being provided is for consumers personal, non-commercial use and may not be used for any purpose other than to identify prospective properties consumers may be interested in purchasing.',
            },
            {
              id: 'disclaimer-copyright',
              type: 'itemContent',
              className: 'pt-2',
              style: {},
              items: [
                {
                  content: 'Listing Information © 2022 ',
                },
                {
                  content: 'MLS Listings Inc',
                  url: 'https://www.mlslistings.com/',
                },
                {
                  content: '. All Rights Reserved.',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  substitute: {},
  replace: {},
  siteData: {
    agency: 'Real Estate Experts',
    agentName: 'Brett Jennings',
    agentDescription: 'Top agents in Northern California',
    agentAvatar: 'https://si-homelight.s3.amazonaws.com/sites/hvar/agent.webp',
    heroBackground: 'https://si-homelight.s3.amazonaws.com/sites/hvar/hero-bg.webp',
  },
};
